import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { MyGalleryVideoRenderer } from "../components/MyGallery";
library.add(fab)

const skillMaxLevel = 5;

const skills = [
    {
        title: "Java",
        image: "/assets/img/skills/java.svg",
        level: "Advanced",
        levelNum: 4.25,
        description: ""
    },
    {
        title: "Java Spring",
        image: "/assets/img/skills/spring.svg",
        level: "Intermediate",
        levelNum: 3.5,
        description: ""
    },
    {
        title: "SQL & databases",
        image: "/assets/img/skills/ds.svg",
        level: "Intermediate",
        levelNum: 3.5,
        description: "PostgreSQL, MySQL, MSSQL, OracleDB"
    },
    {
        title: "ReactJS",
        image: "/assets/img/skills/reactjs.svg",
        level: "Self-taught beginner",
        levelNum: 2.75,
        description: ""
    },
    {
        title: "JavaScript",
        image: "/assets/img/skills/js.svg",
        level: "Intermediate",
        levelNum: 3.5,
        description: ""
    },
    {
        title: "HTML",
        image: "/assets/img/skills/html.svg",
        level: "Intermediate",
        levelNum: 3.5,
        description: ""
    },
    {
        title: "CSS",
        image: "/assets/img/skills/css.svg",
        level: "Intermediate",
        levelNum: 3,
        description: ""
    },
    {
        title: "SASS",
        image: "/assets/img/skills/sass.svg",
        level: "Intermediate",
        levelNum: 3,
        description: ""
    },
    {
        title: "Unity3D",
        image: "/assets/img/skills/unity3d.svg",
        level: "Self-taught intermediate",
        levelNum: 2.75,
        description: ""
    },
    {
        title: "Blender",
        image: "/assets/img/skills/blender.svg",
        level: "Self-taught Intermediate",
        levelNum: 3,
        description: "3D modeling and rigging"
    },
    {
        title: "Autodesk Maya",
        image: "/assets/img/skills/maya.svg",
        level: "Intermediate",
        levelNum: 3,
        description: "3D modeling and rigging"
    },
    {
        title: "C#",
        image: "/assets/img/skills/c-sharp.svg",
        level: "Intermediate",
        levelNum: 2.5,
        description: ""
    },
    {
        title: "C / C++",
        image: "/assets/img/skills/cpp.svg",
        level: "Begginer",
        levelNum: 2,
        description: ""
    },
    {
        title: "Python",
        image: "/assets/img/skills/python.svg",
        level: "Beginner",
        levelNum: 1.5,
        description: ""
    },
    {
        title: "Git",
        image: "/assets/img/skills/git.svg",
        level: "Beginner",
        levelNum: 2,
        description: ""
    }
];


const projects = [
    {
        title: "Personal portfolio",
        descriptions: ["My personal portfolio made with React.js and other web technologies and dependencies to show my skills and projects that I worked on or I am still working."],
        page: "https://danielpoustka.cz/",
        images: [{
            original: process.env.PUBLIC_URL + "/assets/img/projects/portfolio/portfolio-projects.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/portfolio/th_portfolio-projects.png",
            originalAlt: "portfolio-projects_screenshot",
            thumbnailAlt: "portfolio-projects_screenshot_thumbnail",
            originalTitle: "Projects gallery",
            description: "Projects gallery"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/portfolio/portfolio-skills.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/portfolio/th_portfolio-skills.png",
            originalAlt: "portfolio-skills_screenshot",
            thumbnailAlt: "portfolio-skills_screenshot_thumbnail",
            originalTitle: "Skill cards",
            description: "Skill cards"
        }
        ]
    },
    {
        title: "Flashcards - Web application",
        descriptions: [
            "Designing and implementation of educational web application Flashcards as bachelor thesis.",
            "Used technologies: Java, Java Spring, PostgrerSQL, Hibernate, React.js, React redux, Material UI and other web technologies and dependencies.",
            "Backend is currently hosted on Heroku. Please, be patient to server wake up when it is suspended due to free plan."
        ],
        page: "https://danielpoustka.cz/flashcards",
        images: [{
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/collection-detail-play-q.png",
            fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/md_collection-detail-play-q.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_collection-detail-play-q.png",
            originalAlt: "mobile-profile_screenshot",
            thumbnailAlt: "mobile-profile_screenshot_thumbnail",
            originalTitle: "Learning - question",
            description: "Learning - question"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/collection-detail-play-a.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_collection-detail-play-a.png",
            originalAlt: "collection-detail-play-answer_screenshot",
            thumbnailAlt: "collection-detail-play-answer_screenshot_thumbnail",
            originalTitle: "Learning - answer",
            description: "Learning - answer"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/collection-detail.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_collection-detail.png",
            originalAlt: "collection-detail_screenshot",
            thumbnailAlt: "collection-detail_screenshot_thumbnail",
            originalTitle: "Collection detail",
            description: "Collection detail"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/collections-private.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_collections-private.png",
            originalAlt: "collection-private_screenshot",
            thumbnailAlt: "collection-private_screenshot_thumbnail",
            originalTitle: "Own Collections",
            description: "Own Collections"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/collections-private-edit.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_collections-private-edit.png",
            originalAlt: "collection-private-edit_screenshot",
            thumbnailAlt: "collection-private-edit_screenshot_thumbnail",
            originalTitle: "Edit collection",
            description: "Edit collection"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/register.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_register.png",
            originalAlt: "register_page_screenshot",
            thumbnailAlt: "register_page_screenshot_thumbnail",
            originalTitle: "Registration",
            description: "Registration"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/login.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_login.png",
            originalAlt: "login_page_screenshot",
            thumbnailAlt: "login_page_screenshot_thumbnail",
            originalTitle: "Login",
            description: "Login"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/mobile-dashboardWcookie.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_mobile-dashboardWcookie.png",
            originalAlt: "mobile-dashboard_screenshot",
            thumbnailAlt: "mobile-dashboard_screenshot_thumbnail",
            thumbnailHeight: "50px",
            /* originalHeight: "258px", */
            originalTitle: "Mobile - Dashboard",
            description: "Mobile - Dashboard"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/mobile-profile.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/flashcards/th_mobile-profile.png",
            originalAlt: "mobile-profile_screenshot",
            thumbnailAlt: "mobile-profile_screenshot_thumbnail",
            thumbnailHeight: "50px",
            /* originalHeight: "258px", */
            originalTitle: "Mobile - Profile",
            description: "Mobile - Profile"
        },
        ]
    },
    {
        title: "Shift scheduler",
        descriptions: ["[Unfinished] Frontend of web application for planning shifts at workplaces." /* Mainly created for usage by hospital personnel." */,
            "Used technologis: React.js, React Redux, Sass and other web technologies and dependencies"],
        /* page: "https://danielpoustka.cz/smeny/actualBuild.php", */
        images: [
            /* {
                original: process.env.PUBLIC_URL + "/assets/img/projects/smeny/workplace.png",
                //fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/smeny/sc01.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/smeny/th_workplace.png",
                originalAlt: "workplace_screenshot",
                thumbnailAlt: "workplace_screenshot_thumbnail",
                originalTitle: "Gameplay",
                description: "Gameplay"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/smeny/workplace-drag.png",
                //fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/smeny/sc01.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/smeny/th_workplace-drag.png",
                originalAlt: "workplace-drag_screenshot",
                thumbnailAlt: "workplace-drag_screenshot_thumbnail",
                originalTitle: "Shifts dragging",
                description: "Shifts dragging"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/smeny/workplace-load.png",
                //fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/smeny/sc01.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/smeny/th_workplace-load.png",
                originalAlt: "workplace-load_screenshot",
                thumbnailAlt: "workplace-load_screenshot_thumbnail",
                originalTitle: "Gameplay",
                description: "Gameplay"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/smeny/settings.png",
                //fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/smeny/sc01.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/smeny/th_settings.png",
                originalAlt: "settings_screenshot",
                thumbnailAlt: "settings_screenshot_thumbnail",
                originalTitle: "Settings",
                description: "Settings"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/smeny/requests.png",
                //fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/smeny/sc01.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/smeny/th_requests.png",
                originalAlt: "requests_screenshot",
                thumbnailAlt: "requests_screenshot_thumbnail",
                originalTitle: "Requests",
                description: "Requests"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/smeny/requests-absence.png",
                //fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/smeny/sc01.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/smeny/th_requests-absence.png",
                originalAlt: "requests-absence_screenshot",
                thumbnailAlt: "requests-absence_screenshot_thumbnail",
                originalTitle: "Absence request",
                description: "Absence request"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/smeny/requests-shift.png",
                //fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/smeny/sc01.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/smeny/th_requests-shift.png",
                originalAlt: "request-shift_screenshot",
                thumbnailAlt: "request-shift_screenshot_thumbnail",
                originalTitle: "Shift request",
                description: "Shift request"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/smeny/homepage.png",
                //fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/smeny/sc01.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/smeny/th_homepage.png",
                originalAlt: "homepage_screenshot",
                thumbnailAlt: "homepage_screenshot_thumbnail",
                originalTitle: "Homepage",
                description: "Homepage"
            } */
        ]
    },
    {
        title: "Idle hacker clicker game",
        descriptions: ["Pure JavaScript idle clicker game with hacking and cyber security theme. Contains multiple upgrades, notifications, achievements, localization and more!",
            "Used technologies: JavaScript, HTML5, CSS3."],
        page: "https://danielpoustka.cz/game/",
        images: [{
            original: process.env.PUBLIC_URL + "/assets/img/projects/idlehack/md_sc01.png",
            fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/idlehack/sc01.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idlehack/th_sc01.png",
            originalAlt: "idle_hacker_game_screenshot",
            thumbnailAlt: "idle_hacker_game_screenshot_thumbnail",
            originalTitle: "Gameplay",
            description: "Gameplay"
        }]
    },
    {
        title: "Portal gun 3D model",
        descriptions: ["Realistic rigged 3D model (with animation) of Hanheld portal device created with hardsurface modeling techniques. ",
            "Made with Autodesk Maya, rendered with Arnold renderer."],
        page: "",
        images: [{
            original: process.env.PUBLIC_URL + "/assets/img/projects/portalgun/render_final_frontside.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/portalgun/th_render_final_frontside.png",
            originalAlt: "render_final_frontside_image",
            thumbnailAlt: "render_final_frontside_image_thumbnail",
            originalTitle: "Front-side",
            description: "Front-side"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/portalgun/render_final_claw_detail.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/portalgun/th_render_final_claw_detail.png",
            originalAlt: "render_final_claw_detail_image",
            thumbnailAlt: "render_final_claw_detail_image_thumbnail",
            originalTitle: "Claw detail",
            description: "Claw detail"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/portalgun/render_final_wDesk&Sphere.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/portalgun/th_render_final_wDesk&Sphere.png",
            originalAlt: "render_final_wDesk&Sphere_image",
            thumbnailAlt: "render_final_wDesk&Sphere_image_thumbnail",
            originalTitle: "On desk with chrome sphere",
            description: "On desk with chrome sphere"
        }, {
            original: process.env.PUBLIC_URL + "/assets/img/projects/portalgun/Rig_2021_12_17.png",
            thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/portalgun/th_Rig_2021_12_17.png",
            originalAlt: "rig_screenshot_image",
            thumbnailAlt: "rig_screenshot_image_thumbnail",
            originalTitle: "Rig skeleton screenshot",
            description: "Rig skeleton screenshot"
        },
        ]
    },/* 
    {
        title: "Collection of 3D models",
        descriptions: ["Collection of lowpoly 3D models for my other projects or for fun and learning purposes.",
            "Made with Blender."],
        page: "",
        images: [{
            original: "https://via.placeholder.com/100",
            originalHeight: "258px",
            thumbnail: "https://via.placeholder.com/100",
            alt: "mock",
            description: "Mock"
        }]
    }, */
    {
        title: "Oil Quarry Idle Clicker",
        descriptions: ["[Unfinished] Mobile clicker game made with Unity3D. Main goal is to mine more and more oil!",
            "Features: 3D graphics, progression system, custom 3D models, localization (Czech, English and German), click multipliers and more!",
            "Used technologies: Unity3D, C#, VS Code, Blender."],
        page: "https://danielpoustka.cz/game/",
        images: [
            {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/md_game.png",
                fullscreen: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/game.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_game.png",
                originalAlt: "game_screenshot",
                thumbnailAlt: "game_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Gameplay",
                description: "Gameplay"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/offlineProduction.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_offlineProduction.png",
                originalAlt: "offlineProduction_screenshot",
                thumbnailAlt: "offlineProduction_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Offline production",
                description: "Offline production"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/ads.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_ads.png",
                originalAlt: "ads_screenshot",
                thumbnailAlt: "ads_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Rewarded ads",
                description: "Rewarded ads"
            },
            //reward screenshot
            {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/barrel-claim.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_barrel-claim.png",
                originalAlt: "barrel-claim_screenshot",
                thumbnailAlt: "barrel-claim_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Mystery Barrel",
                description: "Mystery Barrel"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/barrel-claimed.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_barrel-claimed.png",
                originalAlt: "barrel-claimed_screenshot",
                thumbnailAlt: "barrel-claimed_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Barrel waiting",
                description: "Barrel waiting"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/settings.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_settings.png",
                originalAlt: "settings_screenshot",
                thumbnailAlt: "settings_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Settings",
                description: "Settings"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/shop.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_shop.png",
                originalAlt: "shop_screenshot",
                thumbnailAlt: "shop_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Upgrades shop",
                description: "Upgrades shop"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/spillage.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_spillage.png",
                originalAlt: "spillage_screenshot",
                thumbnailAlt: "spillage_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Spillage bonus",
                description: "Spillage bonus"
            }, {
                original: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/spillage2.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/idleoil/th_spillage2.png",
                originalAlt: "spillage2_screenshot",
                thumbnailAlt: "spillage2_screenshot_thumbnail",
                thumbnailHeight: "50px",
                originalTitle: "Spillage bonus2",
                description: "Spillage bonus2"
            },
        ]
    },
    {
        title: "Dead aZZault",
        descriptions: ["FPS game made in Unity3D engine during my studies (2015-2019) at SPŠE Ječná in Prague.",
            "Features: 3D graphics, custom graphics and 3D models with handmade animations, PC controlled enemies, 4 types of gun, scoreboard, doom-like cheats.",
            "Used technologies: Unity3D, C#, VS Code, Blender, Gimp."],
        page: "",
        images: [
            {
                original: process.env.PUBLIC_URL + "/assets/img/projects/deadAzzault/DEAD AZZAULT_zz_red.png",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/deadAzzault/th_DEAD AZZAULT_zz_red.png",
                originalAlt: "game_logo_image",
                thumbnailAlt: "game_logo_image_thumbnail",
                originalTitle: "Game logo",
                description: "Game logo"
            },
            {
                original: process.env.PUBLIC_URL + "/assets/img/projects/deadAzzault/Dead Azzault gameplay.mp4",
                thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/deadAzzault/th_DEAD AZZAULT_zz_red.png",
                originalAlt: "gameplaye_video",
                thumbnailAlt: "gameplay_video_thumbnail",
                originalTitle: "Gameplay video",
                description: "v.1.6 gameplay video",
                renderItem: MyGalleryVideoRenderer
            },

        ],
        files: [
            {
                title: "Download Dead aZZault v.1.6",
                path: process.env.PUBLIC_URL + "/assets/img/projects/deadAzzault/Dead aZZault release 1.6.rar"
            }
        ]
    }

];


const contacts = [
    {
        title: "daniel.poustka@gmail.com",
        icon: faEnvelope,
        href: "mailto:daniel.poustka@gmail.com"
    },
    {
        title: "/daniel-poustka",
        icon: ["fab", "linkedin"],
        href: "https://www.linkedin.com/in/daniel-poustka"
    },
    /* {
        title: "GitHub",
        icon: ["fab", "github"],
        href: ""
    } */
];

export const portfolioData =
{
    contacts: contacts,
    skills: skills,
    skillMaxLevel: skillMaxLevel,
    projects: projects,
}