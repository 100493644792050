import React, { useMemo } from 'react'
import { Button } from 'reactstrap'
import { portfolioData } from '../config/portfolioData'
import Section from './Section'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import FadeInElement from '../lib/FadeInElement.js'
import ProjectCard from './ProjectCard'
import { SkillSection } from './SkillSection'
import { NavHashLink } from 'react-router-hash-link'

export default function Homepage()
{
    const { contacts, projects } = portfolioData;


    const memoizedProjects = useMemo(() =>
    {
        return projects.map((el, i) =>
        {
            return (
                <ProjectCard key={"project-card-" + i} title={el.title} descriptions={el.descriptions}
                    page={el.page} images={el.images} files={el.files} isTextLeft={(i % 2)} />
            )
        })
    }, [projects]);

    return (
        <div>
            <header className='text-center'>
                {/* <div className="homepage-graphics" />
                <div className="my-portrait" /> */}
                <div className='me-description'>
                    <img className="my-portrait" src={process.env.PUBLIC_URL + "/assets/img/profile.png"} alt="profile" />
                    <FadeInElement key={"fadein-desc"}>
                        <div className='my-description-wrapper'>
                            <p className="my-description-title">
                                Hi, my name is Daniel Poustka and
                                {/* <span className='invert'>junior Fullstack developer!</span> */}
                                <div class="Iam">
                                    <p>I&apos;m</p>
                                    <b>
                                        <div class="innerIam">
                                            Fullstack<br />
                                            Frontend<br />
                                            Backend<br />
                                            Java<br />
                                            React
                                        </div>
                                    </b>
                                    <p>developer.</p>
                                </div>
                            </p>
                            <p className="my-description">
                                I&apos;m czech junior software engineer with interest in Java, React, JavaScript, game development and more.
                                I&apos;m open for new oppurtunities and interestring projects.
                            </p>
                        </div>
                    </FadeInElement>
                </div>
                <hr style={{ width: "80%", margin: "40px auto 20px auto" }} />
                <span className='banner-contact-icons'>
                    {
                        contacts.map((el, i) =>
                        {
                            return (
                                <NavHashLink to={'./#contacts'} key={"cl" + i}
                                    style={{ display: "inline-flex", padding: "5px", color: "#212529" }}>
                                    <FontAwesomeIcon icon={el.icon} size='2x' />
                                </NavHashLink>
                            )
                        })
                    }
                </span>
                <div id='scrolldown' className='scrolldown'>
                    <NavHashLink to={"./#skills"}><FontAwesomeIcon icon={faArrowDown} /></NavHashLink>
                </div>
            </header>
            <SkillSection />
            <Section title={"Projects"} id={"projects"}>
                {memoizedProjects.map((i) => { return i; })}
            </Section>
            <Section title={"Curiculum Vitae"} id={"curriculum"}>
                <Button color={"secondary"} disabled>Get my CV [PDF]</Button>
            </Section>
            <Section title={"Contact me"} id={"contacts"}>
                <div style={{ margin: "auto", width: "fit-content" }}>
                    {
                        contacts.map((el, i) =>
                        {
                            return (
                                <div key={"c" + i} style={{ display: "flex", width: "fit-content", padding: "5px 10px" }}
                                >
                                    <FontAwesomeIcon icon={el.icon} size='2x' />
                                    {el.href ?
                                        (
                                            <a style={{ marginLeft: "1rem", fontWeight: "bold", fontSize: "1.5rem", textDecoration: "none", color: "#212529" }}
                                                href={el.href}>
                                                {el.title}
                                            </a>
                                        )
                                        :
                                        (
                                            <div style={{ marginLeft: "1rem", fontWeight: "bold", fontSize: "1.5rem" }}
                                                href={el.href}>
                                                {el.title}
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </Section>
            {/*Space after last section*/}
            <div style={{ marginBottom: "5rem" }}></div>
        </div>
    )
}
