import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portfolioData } from '../config/portfolioData';
import FadeInElement from '../lib/FadeInElement';
import skillSlice, { isFinished, isToggledAll } from '../state/skillcards/skillSlice';
import Section from './Section';
import SkillCard from './SkillCard';

export const SkillSection = () =>
{
    const { skillMaxLevel, skills } = portfolioData;

    const isToggledAllSkills = useSelector(isToggledAll);
    const isFinishedSKills = useSelector(isFinished);
    const dispatch = useDispatch();

    const memoizedSkills = useMemo(() =>
    {
        return skills.map((el, i) =>
        {
            return (
                <FadeInElement key={"fadein-skill-card-" + i} onlyOnce={false}>
                    <SkillCard key={"skill-card-" + i} index={i} title={el.title} image={el.image} description={el.description} level={el.levelNum} maxLevel={skillMaxLevel} />
                </FadeInElement>
            )
        })
    }, [skills, skillMaxLevel]);

    const congratz = () =>
    {///TODO Popup / push message/...
        alert("Wow, you have discovered all my skills!");
        dispatch(skillSlice.actions.finish());

        setTimeout(() =>
        {
            dispatch(skillSlice.actions.reset());
        }, 5000);
    }

    return (
        <Section title={"My Skills"} id={"skills"}>
            {isToggledAllSkills && !isFinishedSKills && congratz()}
            <div className="skill-card-container">
                {memoizedSkills.map((i) => { return i; })}
            </div>
        </Section>
    )
};
