import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import useToggle from "../hooks/useToggle"
import skillSlice, { isToggledByIndex } from "../state/skillcards/skillSlice";
import PropTypes from 'prop-types';

function SkillCard({ index, title, image, description, level, maxLevel })
{
    const [isToggleOn, toggle] = useToggle(false);
    const isToggledInState = useSelector((state) => isToggledByIndex(state, index))

    const dispatch = useDispatch();

    useEffect(() =>
    {
        if (isToggledInState && !isToggleOn) toggle();
        else if (!isToggledInState && isToggleOn) toggle();
    }, [isToggledInState, toggle, isToggleOn]);

    const flipCard = () =>
    {
        dispatch(skillSlice.actions.changeVisibility({ index: index, changeTo: !isToggleOn }));
    }

    return (
        <div className={"skill-card"} onClick={flipCard} >
            <div className={"skill-card-inner " + (isToggleOn ? "flip" : "")}>
                <div className="skill-card-front ">
                    <img alt={title + "_img"} src={process.env.PUBLIC_URL + image} />
                    <span>{title}</span>
                </div>
                <div className="skill-card-back">
                    <h6 style={{ marginBottom: "0px" }}>{title}</h6>
                    <StarRatings
                        rating={level}
                        numberOfStars={maxLevel}
                        starDimension='15px'
                        starSpacing='3px'
                        starRatedColor='gold'
                    />
                    <span style={{ fontSize: "small" }}>{description}</span>
                </div>
            </div>
        </div >
    )
}

SkillCard.propTypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    maxLevel: PropTypes.number.isRequired,
}

export default React.memo(SkillCard);