import React, { useState } from 'react';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { NavHashLink } from "react-router-hash-link";

export default function Navigation()
{
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar id="navigation" color="light" light expand="md" fixed="top">
                <Container fluid>
                    <NavbarBrand href="/#"> Poustka Daniel</NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem className="nav-link"><NavHashLink className="nav-link" to={"./#skills"}>Skills</NavHashLink></NavItem>
                            <NavItem className="nav-link"><NavHashLink className="nav-link" to={"./#projects"}>Projects</NavHashLink></NavItem>
                            <NavItem className="nav-link"><NavHashLink className="nav-link" to={"./#curriculum"}>Curiculum Vitae</NavHashLink></NavItem>
                            <NavItem className="nav-link"><NavHashLink className="nav-link" to={"./#contacts"}>Contacts</NavHashLink></NavItem>
                        </Nav>
                        {/* <NavItem className='nav-link ml-auto'><button>dark/light</button></NavItem>*/}{/* TODO */}
                        {/* <NavbarText className="nav-link">Simple Text</NavbarText> */}
                    </Collapse>
                </Container>
            </Navbar >
        </div>
    )
}
