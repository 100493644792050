import React from 'react'
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';

//Mocked images
const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];


const noimage = [{
  original: "https://picsum.photos/id/1019/250/150/?blur",
  //thumbnail: "https://picsum.photos/id/1019/250/150/?blur",
  description: "No image available",
  originalTitle: "No image available",
  originalAlt: "No image available",
  //thumbnailAlt: "No image available",
}]

export const MyGallery = ({ items, additionalClass, ...p }) =>
{
  const imgs = items && items.length > 0 ? items : noimage;
  return (
    <ImageGallery additionalClass={additionalClass} items={imgs} {...p} />
  )
}
MyGallery.propTypes = {
  items: PropTypes.array,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export const MyGalleryVideoRenderer = (item) =>
{
  return (
    <div className='video-wrapper'>
      <video className='image-gallery-image' src={item.original} controls />
      {
        item.description &&
        <span
          className='image-gallery-description'
          style={{ right: '0', left: 'initial' }}
        >
          {item.description}
        </span>
      }
    </div>)
} 
