//import counterReducer from '../features/counter/counterSlice';

import { /* createBrowserHistory, */ createHashHistory } from "history";
import { combineReducers, applyMiddleware, createStore } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import ReduxThunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from "redux-devtools-extension";
import skillSlice from "./state/skillcards/skillSlice";

export const history = createHashHistory();

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: []
};

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers(
        {
            router: connectRouter(history),
            skillReducer: skillSlice.reducer
        }
    )
);

export const store = createStore(
    persistedReducer,
    composeWithDevTools(
        applyMiddleware(routerMiddleware(history), ReduxThunk),
    ),
);

export default function configureAppStore()
{
    let persistor = persistStore(store);
    return { store, persistor };
}