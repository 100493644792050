import { ConnectedRouter as Router } from 'connected-react-router';
import React from 'react'
//import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { /*HashRouter as Router,*/ Route, Switch } from "react-router-dom";
import './App.scss';
import Homepage from './components/Homepage';
import Navigation from './components/Navigation';


import { history } from "./store";

function App()
{
  return (
    <Router history={history}>
      <Navigation />
      <Switch>
        <Route exact path="/" key={"homepage"} >
          <div id="content">
            <Homepage />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
