import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Fading parent element.
 * @param {onlyOnce (default false)} props 
 * @returns 
 */
const FadeInElement = ({ children, onlyOnce = false, ...p }) =>
{
    const domRef = useRef();
    const [isVisible, setVisible] = useState(false);

    useEffect(() =>
    {
        const ref = domRef.current;
        const observer = new IntersectionObserver(entries =>
        {
            if (onlyOnce)
            {
                // In your case there's only one element to observe:     
                if (entries[0].isIntersecting)
                {
                    // Not possible to set it back to false like this:
                    setVisible(true);
                    // No need to keep observing:
                    observer.unobserve(ref);
                }
            }
            else
            {
                setVisible(entries[0].isIntersecting)
            }
        });
        observer.observe(ref);
        return () => { observer.unobserve(ref); }
    }, [onlyOnce]);

    return (
        <div {...p} ref={domRef} className={isVisible ? "fade-in appear" : "fade-in"}>
            {children}
        </div>
    )
}
FadeInElement.propTypes = {
    onlyOnce: PropTypes.bool,
    children: PropTypes.node.isRequired
}
export default React.memo(FadeInElement);