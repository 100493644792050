import React from 'react'
import PropTypes from 'prop-types';


export default function Section({ id, children, title })
{
    return (
        <div id={id} className='section text-center'>
            <h2 className="section-title mt-4">{title}</h2>
            {children}
        </div>
    )
}
Section.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired
}
