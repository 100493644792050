import { createSlice } from "@reduxjs/toolkit";
import { portfolioData } from "../../config/portfolioData";

const createInitialArrayOfCards = (numOfCards) =>
{
    const arr = [];
    for (let i = 0; i < numOfCards; i++)
    {
        arr.push({ toggled: false })
    }
    return arr;
}

const initSkillArr = createInitialArrayOfCards(portfolioData.skills.length);

const skillSlice = createSlice({
    name: 'skillReducer',
    initialState: {
        cards: initSkillArr,
        finished: false
    },
    reducers: {
        /* increment: (state) => { state.current += 1 },
        decrement: (state) => { state.current -= 1 }, */
        reset: (state) => { /* state.current = 0;  */state.finished = false; state.cards = initSkillArr },
        finish: (state) => { state.finished = true },
        changeVisibility: (state, action) =>
        {
            const { index, changeTo } = action.payload;
            state.cards[index].toggled = changeTo;
            /* state.cards.map((c, i) =>
            {
                if (i !== index) return c;

                return { ...c, toggled: !c.toggled }
            }) */
        }
    }
})
export default skillSlice;

export const isToggledAll = (state) => { return state.skillReducer.cards?.filter((c) => { return c.toggled === false }) <= 0 };/* { return state.skillReducer.current >= state.skillReducer.total } */
export const isFinished = (state) => { return state.skillReducer.finished };
export const isToggledByIndex = (state, index) => { return state.skillReducer.cards[index]?.toggled };

