import React from 'react';
import { CardBody, CardHeader, Card, CardText, Button } from 'reactstrap';
import { MyGallery } from './MyGallery';
import PropTypes from 'prop-types';

function ProjectCard({ title, descriptions, page, images, files, isTextLeft, ...p })
{
    return (
        <Card {...p} className="project-card">
            <CardHeader><h5>{title}</h5></CardHeader>
            <CardBody>
                <div className={"row " + (isTextLeft ? "row-reverse" : "")}>
                    <div className="column">
                        {descriptions.map((d, i) => { return (<CardText key={"pc-dt-" + i}>{d}</CardText>) })}
                        {page && <Button color="warning" href={page}>Visit page</Button>}
                        {files && files.map((f, i) =>
                        {
                            return (
                                <Button key={"pc-f-" + i} color="primary" href={f.path} style={{ margin: "0.3em" }}>
                                    {f.title}
                                </Button>
                            )
                        })}
                    </div>
                    <div className="column">
                        <MyGallery additionalClass={"gallery-max-height"} items={images} showBullets={true} lazyLoad={true} infinite={true} />
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}
ProjectCard.propTypes = {
    title: PropTypes.string.isRequired,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            original: PropTypes.string,
            thumbnail: PropTypes.string,
            alt: PropTypes.string
        })
    ),
    isTextLeft: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([0, 1])]).isRequired
}
export default React.memo(ProjectCard);